import { useEffect } from 'react';
import { signout } from 'gatsby-theme-cognito-appsync';
import { navigate } from 'gatsby';

export default function SignOut() {
  useEffect(() => {
    signout();
    navigate('/');
  });
  return null;
}
